import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Decorating a service";
export const _frontmatter = {};
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "decorating-a-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#decorating-a-service",
        "aria-label": "decorating a service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Decorating a service`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#implementing-decoratinghttpservicefunction-and-decoratingrpcservicefunction"
        }}>{`Implementing DecoratingHttpServiceFunction and DecoratingRpcServiceFunction`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#extending-simpledecoratinghttpservice-and-simpledecoratingrpcservice"
        }}>{`Extending SimpleDecoratingHttpService and SimpleDecoratingRpcService`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#extending-decoratingservice"
        }}>{`Extending DecoratingService`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#unwrapping-decoration"
        }}>{`Unwrapping decoration`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#decorating-servicewithroutes"
        }}>{`Decorating ServiceWithRoutes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#decorating-multiple-services-by-path-mapping"
        }}>{`Decorating multiple services by path mapping`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#see-also"
        }}>{`See also`}</a></li>
    </ul>
    <p>{`A 'decorating service' (or a 'decorator') is a service that wraps another service
to intercept an incoming request or an outgoing response. As its name says, it is an implementation of
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Decorator_pattern"
      }}>{`the decorator pattern`}</a>{`. Service decoration takes a crucial role in Armeria. A lot of core features
such as logging, metrics and distributed tracing are implemented as decorators and you will also find it
useful when `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Separation_of_concerns"
      }}>{`separating concerns`}</a>{`.`}</p>
    <p>{`There are basically three ways to write a decorating service:`}</p>
    <ul>
      <li parentName="ul">{`Implementing `}<a parentName="li" {...{
          "href": "type://DecoratingHttpServiceFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/DecoratingHttpServiceFunction.html"
        }}>{`type://DecoratingHttpServiceFunction`}</a>{` and `}<a parentName="li" {...{
          "href": "type://DecoratingRpcServiceFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/DecoratingRpcServiceFunction.html"
        }}>{`type://DecoratingRpcServiceFunction`}</a></li>
      <li parentName="ul">{`Extending `}<a parentName="li" {...{
          "href": "type://SimpleDecoratingHttpService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/SimpleDecoratingHttpService.html"
        }}>{`type://SimpleDecoratingHttpService`}</a>{` and `}<a parentName="li" {...{
          "href": "type://SimpleDecoratingRpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/SimpleDecoratingRpcService.html"
        }}>{`type://SimpleDecoratingRpcService`}</a></li>
      <li parentName="ul">{`Extending `}<a parentName="li" {...{
          "href": "type://DecoratingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/DecoratingService.html"
        }}>{`type://DecoratingService`}</a></li>
    </ul>
    <h2 {...{
      "id": "implementing-decoratinghttpservicefunction-and-decoratingrpcservicefunction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#implementing-decoratinghttpservicefunction-and-decoratingrpcservicefunction",
        "aria-label": "implementing decoratinghttpservicefunction and decoratingrpcservicefunction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Implementing `}<inlineCode parentName="h2">{`DecoratingHttpServiceFunction`}</inlineCode>{` and `}<inlineCode parentName="h2">{`DecoratingRpcServiceFunction`}</inlineCode></h2>
    <p><a parentName="p" {...{
        "href": "type://DecoratingHttpServiceFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/DecoratingHttpServiceFunction.html"
      }}>{`type://DecoratingHttpServiceFunction`}</a>{` and `}<a parentName="p" {...{
        "href": "type://DecoratingRpcServiceFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/DecoratingRpcServiceFunction.html"
      }}>{`type://DecoratingRpcServiceFunction`}</a>{` are functional interfaces that
greatly simplify the implementation of a decorating service. They enables you to write a decorating service
with a single lambda expression:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.HttpResponse;
import com.linecorp.armeria.common.HttpStatus;
import com.linecorp.armeria.server.HttpService;

ServerBuilder sb = Server.builder();
HttpService service = ...;
sb.serviceUnder("/web", service.decorate((delegate, ctx, req) -> {
    if (!authenticate(req)) {
        // Authentication failed; fail the request.
        return HttpResponse.of(HttpStatus.UNAUTHORIZED);
    }

    // Authenticated; pass the request to the actual service.
    return delegate.serve(ctx, req);
});
`}</code></pre>
    <h2 {...{
      "id": "extending-simpledecoratinghttpservice-and-simpledecoratingrpcservice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#extending-simpledecoratinghttpservice-and-simpledecoratingrpcservice",
        "aria-label": "extending simpledecoratinghttpservice and simpledecoratingrpcservice permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Extending `}<inlineCode parentName="h2">{`SimpleDecoratingHttpService`}</inlineCode>{` and `}<inlineCode parentName="h2">{`SimpleDecoratingRpcService`}</inlineCode></h2>
    <p>{`If your decorator is expected to be reusable, it is recommended to define a new top-level class that extends
`}<a parentName="p" {...{
        "href": "type://SimpleDecoratingHttpService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/SimpleDecoratingHttpService.html"
      }}>{`type://SimpleDecoratingHttpService`}</a>{` or `}<a parentName="p" {...{
        "href": "type://SimpleDecoratingRpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/SimpleDecoratingRpcService.html"
      }}>{`type://SimpleDecoratingRpcService`}</a>{` depending on whether you are
decorating an `}<a parentName="p" {...{
        "href": "type://HttpService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/HttpService.html"
      }}>{`type://HttpService`}</a>{` or an `}<a parentName="p" {...{
        "href": "type://RpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/RpcService.html"
      }}>{`type://RpcService`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.HttpRequest;
import com.linecorp.armeria.common.HttpResponse;
import com.linecorp.armeria.server.HttpService;
import com.linecorp.armeria.server.ServiceRequestContext;
import com.linecorp.armeria.server.SimpleDecoratingHttpService;

public class AuthService extends SimpleDecoratingHttpService {
    public AuthService(HttpService delegate) {
        super(delegate);
    }

    @Override
    public HttpResponse serve(ServiceRequestContext ctx, HttpRequest req) throws Exception {
        if (!authenticate(req)) {
            // Authentication failed; fail the request.
            return HttpResponse.of(HttpStatus.UNAUTHORIZED);
        }

        HttpService delegate = unwrap();
        return delegate.serve(ctx, req);
    }
}

ServerBuilder sb = Server.builder();
// Using a lambda expression:
sb.serviceUnder("/web", service.decorate(delegate -> new AuthService(delegate)));
`}</code></pre>
    <h2 {...{
      "id": "extending-decoratingservice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#extending-decoratingservice",
        "aria-label": "extending decoratingservice permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Extending `}<inlineCode parentName="h2">{`DecoratingService`}</inlineCode></h2>
    <p>{`So far, we only demonstrated the case where a decorating service does not transform the type of the request and
response. You can do that as well, of course, using `}<a parentName="p" {...{
        "href": "type://DecoratingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/DecoratingService.html"
      }}>{`type://DecoratingService`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.RpcService;

// Transforms an RpcService into an HttpService.
public class MyRpcService extends DecoratingService<RpcRequest, RpcResponse,
                                                    HttpRequest, HttpResponse> {

    public MyRpcService(Service<? super RpcRequest, ? extends RpcResponse> delegate) {
        super(delegate);
    }

    @Override
    public HttpResponse serve(ServiceRequestContext ctx, HttpRequest req) throws Exception {
        // This method has been greatly simplified for easier understanding.
        // In reality, we will have to do this asynchronously.
        RpcRequest rpcReq = convertToRpcRequest(req);
        RpcResponse rpcRes = unwrap().serve(ctx, rpcReq);
        return convertToHttpResponse(rpcRes);
    }

    private RpcRequest convertToRpcRequest(HttpRequest req) { ... }
    private HttpResponse convertToHttpResponse(RpcResponse res) { ... }
}
`}</code></pre>
    <h2 {...{
      "id": "unwrapping-decoration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#unwrapping-decoration",
        "aria-label": "unwrapping decoration permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Unwrapping decoration`}</h2>
    <p>{`Once a service is decorated, the type of the service is not that of the original service
anymore. Therefore, you cannot simply down-cast it to access the method exposed by the original service.
Instead, you need to 'unwrap' the decorator using the `}<inlineCode parentName="p">{`Service.as()`}</inlineCode>{` method:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`MyService service = ...;
MyDecoratedService decoratedService = service.decorate(...);

assert !(decoratedService instanceof MyService);
assert decoratedService.as(MyService.class) == service;
assert decoratedService.as(MyDecoratedService.class) == decoratedService;
assert decoratedService.as(SomeOtherService.class) == null;
`}</code></pre>
    <p><inlineCode parentName="p">{`as()`}</inlineCode>{` is especially useful when you are looking for the service instances that implements
a certain type from a server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.ServerConfig;
import java.util.List;

Server server = ...;
ServerConfig serverConfig = server.config();
List<ServiceConfig> serviceConfigs = serverConfig.serviceConfigs();
for (ServiceConfig sc : serviceConfigs) {
    if (sc.service().as(SomeType.class) != null) {
        // Handle the service who implements or extends SomeType.
    }
}
`}</code></pre>
    <h2 {...{
      "id": "decorating-servicewithroutes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#decorating-servicewithroutes",
        "aria-label": "decorating servicewithroutes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Decorating `}<inlineCode parentName="h2">{`ServiceWithRoutes`}</inlineCode></h2>
    <p><a parentName="p" {...{
        "href": "type://ServiceWithRoutes:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceWithRoutes.html"
      }}>{`type://ServiceWithRoutes`}</a>{` is a special variant of service which allows a user to register multiple
routes for a single service. It has a method called `}<a parentName="p" {...{
        "href": "type://ServiceWithRoutes#routes():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceWithRoutes.html#routes()"
      }}>{`type://ServiceWithRoutes#routes()`}</a>{` which returns a `}<inlineCode parentName="p">{`Set`}</inlineCode>{` of
`}<a parentName="p" {...{
        "href": "typeplural://Route:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Route.html"
      }}>{`typeplural://Route`}</a>{` so that you do not have to specify path when registering your service:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.Route;
import com.linecorp.armeria.server.HttpServiceWithRoutes;
import java.util.HashSet;
import java.util.Set;

public class MyServiceWithRoutes implements HttpServiceWithRoutes {
    @Override
    public HttpResponse serve(ServiceRequestContext ctx, HttpRequest req) { ... }

    @Override
    public Set<Route> routes() {
        Set<Route> routes = new HashSet<>();
        routes.add(Route.builder().path("/services/greet").build());
        routes.add(Route.builder().path("/services/hello").build());
        return routes;
    }
}

ServerBuilder sb = Server.builder();
// No path is specified.
sb.service(new MyServiceWithRoutes());
// Override the path provided by routes().
sb.service("/services/hola", new MyServiceWithRoutes());
`}</code></pre>
    <p>{`However, decorating a `}<a parentName="p" {...{
        "href": "type://ServiceWithRoutes:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceWithRoutes.html"
      }}>{`type://ServiceWithRoutes`}</a>{` can lead to a compilation error when you attempt to
register it without specifying a path explicitly, because a decorated service is not a
`}<a parentName="p" {...{
        "href": "type://ServiceWithRoutes:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceWithRoutes.html"
      }}>{`type://ServiceWithRoutes`}</a>{` anymore but just a service:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.logging.LoggingService;

ServerBuilder sb = Server.builder();

// Works.
HttpServiceWithRoutes service = new MyServiceWithRoutes();
sb.service(service);

// Does not work - not a HttpServiceWithRoutes anymore due to decoration.
HttpService decoratedService = service.decorate(LoggingService.newDecorator());
sb.service(decoratedService); // Compilation error

// Works if a path is specified explicitly.
sb.service("/services/bonjour", decoratedService);
`}</code></pre>
    <p>{`Therefore, you need to specify the decorators as extra parameters:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ServerBuilder sb = Server.builder();
// Register a service decorated with two decorators at multiple routes.
sb.service(new MyServiceWithRoutes(),
           MyDecoratedService::new,
           LoggingService.newDecorator())
`}</code></pre>
    <p>{`A good real-world example of `}<a parentName="p" {...{
        "href": "type://ServiceWithRoutes:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceWithRoutes.html"
      }}>{`type://ServiceWithRoutes`}</a>{` is `}<a parentName="p" {...{
        "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
      }}>{`type://GrpcService`}</a>{`.
See `}<a parentName="p" {...{
        "href": "/docs/server-grpc#decorating-a-grpcservice"
      }}>{`Decorating a GrpcService`}</a>{` for more information.`}</p>
    <h2 {...{
      "id": "decorating-multiple-services-by-path-mapping",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#decorating-multiple-services-by-path-mapping",
        "aria-label": "decorating multiple services by path mapping permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Decorating multiple services by path mapping`}</h2>
    <p>{`If you want to decorate multiple `}<a parentName="p" {...{
        "href": "typeplural://Service:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Service.html"
      }}>{`typeplural://Service`}</a>{` by path mapping or router matching, you can specify
decorators using `}<inlineCode parentName="p">{`decoratorUnder(pathPrefix, ...)`}</inlineCode>{` or `}<inlineCode parentName="p">{`decorator(Route, ...)`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.HttpHeaderNames;

VipService vipService = ...;
MemberService memberService = ...;
HtmlService htmlService = ...;
JsService jsService = ...;

ServerBuilder sb = Server.builder();

// Register vipService and memberService under '/users' path
sb.annotatedService("/users/vip", vipService)
  .annotatedService("/users/members", memberService);

// Decorate all services under '/users' path
sb.decoratorUnder("/users", (delegate, ctx, req) -> {
    if (!authenticate(req)) {
        return HttpResponse.of(HttpStatus.UNAUTHORIZED);
    }
    return delegate.serve(ctx, req);
});

// Register htmlService and jsService under '/public' path
sb.serviceUnder("/public/html", htmlService)
  .serviceUnder("/public/js", jsService);

// Decorate services only when a request method is 'GET'
sb.decorator(Route.builder().get("/public").build(), (delegate, ctx, req) -> {
    final HttpResponse response = delegate.serve(ctx, req);
    ctx.mutateAdditionalResponseHeaders(
            mutator -> mutator.add(HttpHeaderNames.CACHE_CONTROL, "public"));
    return response;
});
`}</code></pre>
    <p>{`You can also use fluent route builder with `}<inlineCode parentName="p">{`routeDecorator()`}</inlineCode>{` to match services being decorated.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ServerBuilder sb = Server.builder();

// Register vipService and memberService under '/users' path
sb.annotatedService("/users/vip", vipService)
  .annotatedService("/users/members", memberService);

// Decorate services under '/users' path with fluent route builder
sb.routeDecorator()
  .pathPrefix("/users")
  .build((delegate, ctx, req) -> {
      if (!authenticate(req)) {
          return HttpResponse.of(HttpStatus.UNAUTHORIZED);
      }
      return delegate.serve(ctx, req);
  });

// Register htmlService and jsService under '/public' path
sb.serviceUnder("/public/html", htmlService)
  .serviceUnder("/public/js", jsService);

// Decorate services under '/public' path using 'get' method with path pattern
sb.routeDecorator()
  .get("prefix:/public")
  .build((delegate, ctx, req) -> {
      final HttpResponse response = delegate.serve(ctx, req);
      ctx.mutateAdditionalResponseHeaders(
              mutator -> mutator.add(HttpHeaderNames.CACHE_CONTROL, "public"));
      return response;
  });
`}</code></pre>
    <p>{`Please refer to `}<a parentName="p" {...{
        "href": "type://DecoratingServiceBindingBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/DecoratingServiceBindingBuilder.html"
      }}>{`type://DecoratingServiceBindingBuilder`}</a>{` for more information.`}</p>
    <h2 {...{
      "id": "see-also",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#see-also",
        "aria-label": "see also permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`See also`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/client-decorator"
        }}>{`Decorating a client`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/advanced-custom-attributes"
        }}><inlineCode parentName="a">{`RequestContext`}</inlineCode>{` custom attributes`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      